import { useTranslation } from 'react-i18next'
import { useRequest } from 'ahooks'
import { produce } from 'immer'
import { Button, Card, Col, Empty, Flex, GetProps, message, Modal, Row, Spin, theme } from 'antd'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'

import { SPIN_DELAY } from '@/components/Loading'
import { setGlobalState } from '@/globalStore/globalStore'
import { toThousands } from '@/utils/utils'
import { NumPrivacyShow } from '../../components/NumPrivacyShow'
import { AddOrEditBankModal } from './AddOrEditBankModal'
import { AddOrEditCompanyModal } from './AddOrEditCompanyModal'
import { apiDelBank, apiDelCompany, apiGetData } from './apts'
import { EditUserInfoModal } from './EditUserInfoModal'
import { popupSlot } from './utils'

const showVal = (val: string | null | undefined) => val || '--'

export const Billing = () => {
  const { t } = useTranslation()
  const { token } = theme.useToken()
  const innerRowProps: GetProps<typeof Row> = { style: { gap: 6 } }
  const innerColProps = { xxl: 8, sm: 12, xs: 12 }
  const labelProps: GetProps<typeof Col> = {
    flex: '0 0 90px',
    style: { textAlign: 'right', color: token.colorTextLabel },
  }
  const ct1Props: GetProps<typeof Col> = { flex: '1 1 calc(100% - 90px * 1.5)' }

  const { loading, data, run, mutate } = useRequest(apiGetData)

  return (
    <div style={{ overflow: 'hidden' }}>
      <Spin spinning={loading} delay={SPIN_DELAY}>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Card
              title={t('Setting.ge-ren-xin-xi')}
              extra={
                data && (
                  <Button
                    type="link"
                    icon={<EditOutlined />}
                    onClick={() => {
                      EditUserInfoModal.open({
                        userName: data.userName,
                        email: data.email,
                        onSuccess: ({ userName }) => {
                          mutate(
                            produce(data, draft => {
                              draft.userName = userName
                            }),
                          )
                          setGlobalState(prev =>
                            produce(prev, draft => {
                              draft.userInfo!.userName = userName || ''
                            }),
                          )
                        },
                      })
                    }}
                  />
                )
              }
            >
              <Row {...innerRowProps}>
                <Col {...labelProps}>{t('Setting.yong-hu-ming')}&nbsp;</Col>
                <Col {...ct1Props}>{showVal(data?.userName)}</Col>
                <Col {...labelProps}>{t('2-common.you-xiang')}&nbsp;</Col>
                <Col {...ct1Props}>{showVal(data?.email)}</Col>
              </Row>
            </Card>
          </Col>

          <Col span={12}>
            <Card title={t('Setting.wo-de-zhang-hu-yu-e')}>
              <Row {...innerRowProps}>
                <Col {...labelProps}>{t('Setting.ke-yong-yu-e')}&nbsp;</Col>
                <Col {...ct1Props}>{toThousands(data?.availableBalance)}</Col>
                <Col {...labelProps}>{t('Setting.dong-jie-yu-e')}&nbsp;</Col>
                <Col {...ct1Props}>{toThousands(data?.frozenBalance)}</Col>
              </Row>
            </Card>
          </Col>

          <Col span={24}>
            <Card
              title={t('Setting.gong-si-xin-xi')}
              extra={
                data && (
                  <Button
                    type="link"
                    icon={<PlusOutlined />}
                    onClick={() => AddOrEditCompanyModal.open({ onSuccess: () => run() })}
                  >
                    {t('Setting.tian-jia-gong-si')}
                  </Button>
                )
              }
            >
              <Row gutter={[16, 16]} style={{ minHeight: 120 }}>
                {data?.companyList.map(item => (
                  <Col {...innerColProps} key={item.id}>
                    <Card>
                      <Flex align="center" justify="space-between" gap={6}>
                        <Row {...innerRowProps}>
                          <Col {...labelProps}>{t('Setting.gong-si-ming-cheng')}&nbsp;</Col>
                          <Col {...ct1Props}>{showVal(item.companyName)}</Col>
                          <Col {...labelProps}>{t('Setting.gong-si-di-zhi')}&nbsp;</Col>
                          <Col {...ct1Props}>{showVal(item.address)}</Col>
                          <Col {...labelProps}>{t('Setting.shui-hao-vat')}&nbsp;</Col>
                          <Col {...ct1Props}>{showVal(item.dutyNumber)}</Col>
                          <Col {...labelProps}>ioss&nbsp;</Col>
                          <Col {...ct1Props}>{showVal(item.ioss)}</Col>
                        </Row>
                        <div style={{ whiteSpace: 'nowrap' }}>
                          <Button
                            type="link"
                            icon={<EditOutlined />}
                            onClick={() => {
                              AddOrEditCompanyModal.open({
                                data: item,
                                onSuccess: changedData => {
                                  mutate(
                                    produce(data, draft => {
                                      const target = draft.companyList.find(d => d.id === item.id)
                                      if (target) Object.assign(target, changedData)
                                    }),
                                  )
                                },
                              })
                            }}
                          />
                          <Button
                            type="link"
                            icon={<DeleteOutlined />}
                            onClick={() => {
                              Modal.confirm({
                                title: t('2-common.que-ren-yao-shan-chu-ma'),
                                content: item.companyName,
                                onOk: async () => {
                                  await apiDelCompany(item.id)
                                  message.success(t('2-common.cao-zuo-cheng-gong'))
                                  mutate(
                                    produce(data, draft => {
                                      draft.companyList = draft.companyList.filter(d => d.id !== item.id)
                                    }),
                                  )
                                },
                              })
                            }}
                          />
                        </div>
                      </Flex>
                    </Card>
                  </Col>
                ))}
                {data && !data.companyList.length && <Empty style={{ margin: '0 auto' }} />}
              </Row>
            </Card>
          </Col>

          <Col span={24}>
            <Card
              title={t('Setting.wo-de-yin-hang-ka')}
              extra={
                data && (
                  <Button
                    type="link"
                    icon={<PlusOutlined />}
                    onClick={() => AddOrEditBankModal.open({ onSuccess: () => run() })}
                  >
                    {t('Setting.tian-jia-yin-hang-ka')}
                  </Button>
                )
              }
            >
              <Row gutter={[16, 16]} style={{ minHeight: 120 }}>
                {data?.bankCardList.map(item => (
                  <Col {...innerColProps} key={item.id}>
                    <Card>
                      <Flex align="center" justify="space-between" gap={6}>
                        <Row {...innerRowProps}>
                          <Col {...labelProps}>{t('Setting.zhang-hu-ming')}&nbsp;</Col>
                          <Col {...ct1Props}>{showVal(item.account)}</Col>
                          <Col {...labelProps}>{t('Setting.yin-hang-ming-cheng')}&nbsp;</Col>
                          <Col {...ct1Props}>{showVal(item.bankName)}</Col>
                          <Col {...labelProps}>{t('Setting.yin-hang-ka-hao')}&nbsp;</Col>
                          <Col {...ct1Props}>
                            <NumPrivacyShow value={item.cardNumber} />
                          </Col>
                        </Row>
                        <div style={{ whiteSpace: 'nowrap' }}>
                          <Button
                            type="link"
                            icon={<EditOutlined />}
                            onClick={() => {
                              AddOrEditBankModal.open({
                                data: item,
                                onSuccess: changedData => {
                                  mutate(
                                    produce(data, draft => {
                                      const target = draft.bankCardList.find(d => d.id === item.id)
                                      if (target) Object.assign(target, changedData)
                                    }),
                                  )
                                },
                              })
                            }}
                          />
                          <Button
                            type="link"
                            icon={<DeleteOutlined />}
                            onClick={() => {
                              Modal.confirm({
                                title: t('2-common.que-ren-yao-shan-chu-ma'),
                                content: (
                                  <>
                                    <div>{item.account}</div>
                                    <div>{item.bankName}</div>
                                    <NumPrivacyShow value={item.cardNumber} />
                                  </>
                                ),
                                onOk: async () => {
                                  await apiDelBank(item.id)
                                  message.success(t('2-common.cao-zuo-cheng-gong'))
                                  mutate(
                                    produce(data, draft => {
                                      draft.bankCardList = draft.bankCardList.filter(d => d.id !== item.id)
                                    }),
                                  )
                                },
                              })
                            }}
                          />
                        </div>
                      </Flex>
                    </Card>
                  </Col>
                ))}
                {data && !data.bankCardList.length && <Empty style={{ margin: '0 auto' }} />}
              </Row>
            </Card>
          </Col>
        </Row>
      </Spin>

      <popupSlot.Slot />
    </div>
  )
}
